import { useEffect, useState } from 'react'
import { isLoggedInCustomer, useUser, useUserAccounts, useUserBetSettings } from '../lib/user'
import { getGeoComplyLicense, setGeoComplyGeoLocation } from '../lib/account'
import { useSessionClient } from '../lib/api-client'
import { useRouter } from 'next/router'
import { alertService } from '../services/alert.service'

declare global {
  interface Window {
    GcHtml5: any
  }
}

export const useGeoComply = () => {
  const { user, mutateUser } = useUser()
  const { mutateUserAccounts } = useUserAccounts()
  const { mutateUserBetSettings } = useUserBetSettings()
  const router = useRouter()
  const sessionClient = useSessionClient()
  const [geoClient, setGeoClient] = useState(null)
  const [myIpOptions, setMyIpOptions] = useState(null)

  useEffect(() => {
    if (typeof window.GcHtml5 !== 'undefined' && geoClient == null) {
      //console.log('GeoClient Hook')
      setGeoClient(window.GcHtml5.createClient())
    }
  }, [])

  useEffect(() => {
    if (isLoggedInCustomer(user)) {
      initEvents()
      initGeoComplyMyIpService()
      window.GcHtml5.stopMyIpService()
    }
  }, [user, geoClient])

  const initGeoComplyMyIpService = async () => {
    registerMyIpEvents()
    startGcMyIpService()
  }

  const initEvents = () => {
    if (geoClient != null) {
      //console.log('INIT EVENT')
      geoClient.events
        .on('success', async function (geoPackage, xml) {
          console.log('GEOLOCATION SUCCESS: ')
          // Send the geoPackage to your decrypted geo package API server and handle its response
          let geoLocation = await setGeoComplyGeoLocation(geoPackage)
          console.log('GEOLOCATION USER IS ALLOWED: ', geoLocation.userIsAllowed)
          if (!geoLocation.userIsAllowed) {
            alertService.error('GEOCOMPLYERROR')
            /*try {
              await signOutUser()
              await Promise.all([mutateUser(), mutateUserAccounts(), mutateUserBetSettings()])
            } finally {
              setTimeout(() => router.push('/account/login'), 1000)
            }*/
            await router.replace('/account/logout?redirectTo=geoblocked')
          } else {
            window.GcHtml5.startMyIpService(myIpOptions)
          }
        })
        .on('failed', function (code) {
          const { ErrorCodes, REASON_CODE } = geoClient
          console.log('GEOLOCATION FAILED: ', code)
          let message = ''
          switch (code) {
            case ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED:
            case ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT:
            case ErrorCodes.CLNT_ERROR_LICENSE_UNAUTHORIZED:
              // The app should get the license from the server again to make sure it uses the latest one.
              // After receiving the new license, the app should call geoClient.request function again for a new geolocation request.

              //trigger license get
              //geolocate(REASON_CODE.USER_DRIVEN_RETRY)
              break
            case ErrorCodes.CLNT_ERROR_CLIENT_SUSPENDED:
            case ErrorCodes.CLNT_ERROR_DISABLED_SOLUTION:
              // The GeoComply service has been disabled.
              // Therefore, the app needs to display a UI indicating that
              // the user should ask Customer Support for help.
              break
            case ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION:
              // The Internet connection is not good.
              // The app should show a message to ask the user to check the Internet connection.

              /*message =
                "Oops! It seems like there's a problem with the network connection. Check your Internet connection and try again."
              alert(message)*/
              break
            case ErrorCodes.CLNT_ERROR_SERVER_COMMUNICATION:
              // There are many reasons for this error:
              // - Internet connection was not good
              // - GeoComply server was too busy
              // - GeoComply server had internal error
              // - etc
              // For this error, the app can show a UI to indicate that
              // the Internet connection is not good or the server is too busy.
              // The user can retry after a few seconds/minutes.

              /*message =
                "Oops! It seems like there's a problem with the network connection or the server was too busy. Check your Internet connection and try again after 15 seconds."
              alert(message)*/
              break
            case ErrorCodes.CLNT_ERROR_INVALID_CUSTOM_FIELDS:
              // The app needs to set valid data for custom fields.
              // Then make a new geolocation request.
              // NOTE: This error won't happen if the app inputs all custom fields with a valid format.

              // No custom fields at the moment
              break
            case ErrorCodes.CLNT_ERROR_REQUIRE_REASON_CODE:
              // The app needs to set a reason code for geoClient.
              // Then make a new geolocation request.
              // NOTE: The app can void this error by always calling geoClient.setReasonCode for each geolocation request.
              break
            default:
              // The app can show a general message and ask the user to try again after some seconds/minutes or contact Customer Support for help.
              let message =
                'Oops! Something went wrong! Try again in a few seconds/minutes or contact Customer Support for help.'
              break
          }
        })
    }
  }
  const geolocate = async (reasonCode: GeoComplyReasonCode, reason: GeoComplyReason, userid: number) => {
    let license = await getGeoComplyLicense()
    if (license.enabled) {
      console.log('GEOLOCATE')
      window.GcHtml5.stopMyIpService()

      geoClient.setUserId(userid)

      // Make sure you set the appropriate reason code for this request
      switch (reasonCode) {
        case GeoComplyReasonCode.INTERVAL:
          geoClient.setReasonCode(geoClient.REASON_CODE.INTERVAL)
          break
        case GeoComplyReasonCode.LOGIN:
          geoClient.setReasonCode(geoClient.REASON_CODE.LOGIN)
          break
      }

      // You can set geolocation reason with any free-typed string
      geoClient.setReason(reason.valueOf())

      // You can set as many custom fields as you want
      // geoClient.customFields.set('custom_field_demo', 'demo1')
      // geoClient.customFields.set('custom_field_demo2', 'demo2')

      // You can remove any custom fields you don't want
      // geoClient.customFields.remove('custom_field_demo2')

      geoClient.setLicense(license.license)
      geoClient.request({
        acceptNoCoordinates: true,
      })
    }
  }

  const registerMyIpEvents = () => {
    if (geoClient == null || geoClient?.onMyIpSuccess != null || geoClient?.onMyIpFailure != null) {
      return
    }
    geoClient.onMyIpSuccess = async function (ip) {
      window.GcHtml5.stopMyIpService()
      console.log('[onMyIpSuccess] ' + ip)
      const license = await getGeoComplyLicense()
      //console.log(user.id.toString())
      if (license.enabled) {
        // Acknowledge the new IP is handled by the app
        geoClient.ackMyIpSuccess()
        // Invalidate the current geolocation token
        // Trigger a geolocation request with REASON_CODE.IPCHANGE to get a new token
        geoClient.setUserId(user.id.toString())
        geoClient.setUserSessionId('user_session_id')
        geoClient.setReasonCode(geoClient.REASON_CODE.IPCHANGE)
        geoClient.setReason(GeoComplyReason.IPCHANGE.valueOf())
        geoClient.customFields.set('geo_ip_detect', 'gc_ip_change_detect')

        geoClient.setLicense(license.license)
        geoClient.request()
      }
    }
    geoClient.onMyIpFailure = function (code, message) {
      console.log('MyIp Failure')
      window.GcHtml5.stopMyIpService()
      const { ErrorCodes } = geoClient
      switch (code) {
        case ErrorCodes.CLNT_ERROR_MYIP_LICENSE_NOT_SET:
          // Set license before starting the IP change detection service.
          console.log('IP Not Set')
          break
        case ErrorCodes.CLNT_ERROR_MYIP_STOPPED:
          // Confirm MyIP service stopped after the app invokes the method to stop.
          console.log('IP Stopped')
          break
        default:
          // Handle other errors.
          break
      }
    }
  }

  async function startGcMyIpService() {
    const license = await getGeoComplyLicense()
    if (license.enabled) {
      setMyIpOptions({
        license: license.license,
        resumable: true,
      })
    }
  }

  return {
    geolocate,
    initGeoComplyMyIpService,
  }
}

export enum GeoComplyReasonCode {
  LOGIN = 1,
  INTERVAL = 3,
  IPCHANGE = 4,
}

export enum GeoComplyReason {
  LOGIN = 'Login',
  INTERVAL = 'Interval',
  IPCHANGE = 'IpChange',
}

export default useGeoComply
