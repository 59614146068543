import { Fragment, ReactNode, useEffect } from 'react'
import { HeaderProps, SubHeader, SubHeaderProps } from './Header'
import { BeatLoader } from 'react-spinners'

import { AnimatePresence } from 'framer-motion'
import { useRouter } from 'next/router'
import { useLayoutContext } from '../../context/layout.context'
import { useSportsBettingSettings } from '../../lib/content'
import { OddValueFormat } from '../../hooks/useOddsFormat'
import { useCommonContext } from '../../context/common.context'
import dynamic from 'next/dynamic'
import {
  renewSessionGeolocationInterval,
  useSessionGeoLocationInterval,
  useSessionTimeLimitCheck,
} from '../../lib/account'
import { useUser } from '../../lib/user'
import SessionTimeLimitModal from '../account/modals/SessionTimeLimitModal'
import useGeoComply, { GeoComplyReason, GeoComplyReasonCode } from '../../hooks/useGeoComplyGeoLocation'

const Header = dynamic(() => import('./Header'))
const Footer = dynamic(() => import('./Footer'))
const BottomNav = dynamic(() => import('./BottomNav'))
const SideNav = dynamic(() => import('./SideNav'))
const WidgetContainer = dynamic(() => import('./WidgetContainer'))
const AlertContainer = dynamic(() => import('../core/AlertContainer'))

export type ViewProps = {
  pageId: string
  headerProps?: HeaderProps
  subHeaderProps?: SubHeaderProps
  addHeader?: boolean
  addSubHeader?: boolean
  addFooter?: boolean
  addBottomNav?: boolean
  betSlipToggleDisabled?: boolean
  children: ReactNode
  leftWidgets?: ReactNode[]
  rightWidgets?: ReactNode[]
  maxMainContentWidth?: string
}

const View = ({
  children,
  pageId,
  addFooter = true,
  addHeader = true,
  addSubHeader = false,
  headerProps = {},
  subHeaderProps = {},
  addBottomNav = true,
  betSlipToggleDisabled = false,
  leftWidgets = [],
  rightWidgets = [],
  maxMainContentWidth = undefined,
}: ViewProps) => {
  const layoutContext = useLayoutContext()
  const commonContext = useCommonContext()
  const sportsBettingSettings = useSportsBettingSettings()
  const addStakeSelector =
    headerProps.addStakeSelector &&
    (commonContext.oddsFormat === OddValueFormat.PotentialWinnings || sportsBettingSettings.showStakeSelector)

  const displayLeftWidgetContainer =
    leftWidgets?.length > 0 &&
    (layoutContext.currentBreakPoint === 'tablet' || layoutContext.currentBreakPoint === 'desktop')

  const displayRightWidgetContainer = rightWidgets?.length > 0 && layoutContext.currentBreakPoint === 'desktop'

  const { user } = useUser()
  const { geolocate } = useGeoComply()
  const { sessionTimeLimit } = useSessionTimeLimitCheck(user)
  const { sessionGeoLocationTime, mutateSessionGeoLocationTime } = useSessionGeoLocationInterval(user)

  useEffect(() => {
    const handleSessionGeoLocationInterval = async () => {
      await geolocate(GeoComplyReasonCode.INTERVAL, GeoComplyReason.INTERVAL, user?.id)
      await renewSessionGeolocationInterval()
      await mutateSessionGeoLocationTime()
    }
    if (sessionGeoLocationTime?.triggerGeoLocation) {
      handleSessionGeoLocationInterval().catch(console.error)
    }
  }, [sessionGeoLocationTime])

  return (
    <div className="view flex flex-col min-h-[var(--doc-height)]">
      <div className="view-header sticky top-0 w-screen flex flex-col z-[15]">
        {addHeader && <Header {...headerProps} addStakeSelector={addStakeSelector} />}
        {addSubHeader && <SubHeader {...subHeaderProps} />}
      </div>

      <ViewSideNav />

      <div id="view-content" className={`flex relative grow justify-center w-screen min-h-full h-full`}>
        <div className="view-content-inner flex items-start w-full max-w-appMaxWidth min-h-full">
          {displayLeftWidgetContainer && (
            <WidgetContainer
              className={`view-widget-container-left flex sticky md:top-[calc(48px+var(--header-height))] md:h-[calc(var(--doc-height)-100px-var(--header-height))] xl:top-[var(--header-height)] md:w-widgetContainerLeft xl:h-[calc(var(--doc-height)-var(--header-height))] bg-contentLeft text-contentLeftContrast border-r-[1px] border-neutral flex-shrink-0 overflow-hidden`}
            >
              {leftWidgets?.map((widget, index) => <Fragment key={index}>{widget}</Fragment>)}
            </WidgetContainer>
          )}

          <div
            className={`view-widget-container-main flex flex-col justify-center items-center w-full flex-grow overflow-x-clip bg-contentMain text-contentMainContrast 
              ${displayLeftWidgetContainer ? 'md:w-[calc(100%-var(--widget-container-left-width))]' : ''}
              ${displayLeftWidgetContainer && !displayRightWidgetContainer ? 'xl:w-[calc(100%-var(--widget-container-left-width))]' : ''}
              ${!displayLeftWidgetContainer && displayRightWidgetContainer ? 'xl:w-[calc(100%-var(--widget-container-right-width))]' : ''} 
              ${displayLeftWidgetContainer && displayRightWidgetContainer ? 'xl:w-[calc(100%-var(--widget-container-left-width)-var(--widget-container-right-width))]' : ''}
              ${addBottomNav ? 'pb-[calc(var(--bottom-nav-height)+var(--safe-area-inset-bottom))] xl:pb-0' : ''}`}
          >
            <div
              id={pageId}
              className={`view-widget-container-main-inner flex flex-col flex-grow shrink-0 w-full relative ${
                addStakeSelector
                  ? 'min-h-[calc(var(--doc-height)-152px-var(--safe-area-inset-bottom))]'
                  : 'min-h-[calc(var(--doc-height)-104px-var(--safe-area-inset-bottom))]'
              } xl:min-h-[calc(var(--doc-height)-52px-var(--safe-area-inset-bottom))] ${
                maxMainContentWidth ? `max-w-[${maxMainContentWidth}]` : ''
              } ${addFooter || betSlipToggleDisabled || !addBottomNav ? '' : 'pb-[56px] xl:pb-0'}`}
              style={{ maxWidth: maxMainContentWidth ? maxMainContentWidth : '100%' }}
            >
              {children}
            </div>

            {addFooter && <Footer betSlipToggleDisabled={betSlipToggleDisabled || !addBottomNav} />}
          </div>

          {displayRightWidgetContainer && (
            <WidgetContainer
              className={`view-widget-container-right flex sticky top-[var(--header-height)] w-widgetContainerRight h-[calc(var(--doc-height)-var(--header-height))] pb-[106px] bg-contentRight text-contentRightContrast flex-shrink-0 border-l-[1px] border-neutral overflow-hidden`}
            >
              {rightWidgets?.map((widget, index) => <Fragment key={index}>{widget}</Fragment>)}
            </WidgetContainer>
          )}
        </div>

        <div className="view-alert-container hidden xl:flex fixed right-6 top-[68px] z-40">
          <AlertContainer />
        </div>

        {sessionTimeLimit?.mustConfirm && <SessionTimeLimitModal />}
      </div>

      {addBottomNav && (
        <div className="view-bottom-nav fixed bottom-0 flex flex-col z-[20] xl:hidden">
          <BottomNav betSlipToggleDisabled={betSlipToggleDisabled} />
        </div>
      )}

      <ViewLoadingIndicator />
    </div>
  )
}

const ViewSideNav = () => {
  const { sideNavActive, setSideNavActive } = useLayoutContext()

  return (
    <div className="view-sidenav z-[25]">
      <AnimatePresence>{sideNavActive && <SideNav closeMenu={() => setSideNavActive(false)} />}</AnimatePresence>
    </div>
  )
}

const ViewLoadingIndicator = () => {
  const router = useRouter()
  const { loadingState, setSideNavActive } = useLayoutContext()

  useEffect(() => {
    router.events.on('routeChangeStart', () => setSideNavActive(false))
  }, [])

  return (
    <>
      {loadingState.loading && (
        <div className="view-loading-indicator fixed top-0 left-0 flex flex-col justify-center items-center h-screen h-screen-ios w-screen bg-gray-500 bg-opacity-50 z-50">
          <BeatLoader color={'var(--primary)'} />
          {loadingState.loadingText != '' && <p className="font-semibold text-primary">{loadingState.loadingText}</p>}
        </div>
      )}
    </>
  )
}

export default View
